import React from "react";
import {
  Button,
  Paper,
  Typography,
  IconButton,
  Modal,
  Box,
  Divider,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
// import { LocalStorageKeys } from '../../utils';
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { MessageConfiguration } from "./messageConfiguration";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  DialogContext,
  AlertContext,
  ProjectInfoContext,
  BackdropContext,
} from "../../contexts";
import { LinearProgress } from "@material-ui/core";
import { AlertProps } from "../../utils";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Databaseicon } from "../../assets";
import { CloneIcon } from "../../assets";
// import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import { ProjectName } from "../../assets/development";
// import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { IsRequired } from "../../components";
import {
  AxiosCall,
  // getRuleData,
  heyCloneThisRule,
  HeyCloneThisScenario,
  // upsertRule,
} from "../Scenario";
import {
  getRepoid,
  getRepoIdStateMapping,
  softDeleteRepo,
} from "../../utils/apiCall";
const useStyles = makeStyles((theme) => ({
  root: {
    // textAlign: "center",
    // marginTop: "20%"
    // height: "Calc(100vh - 64px)",
    // height: "100%",
    background: theme.palette.background.common,
    flexGrow: "1",
  },
  title: {
    display: "block",
    marginLeft: theme.spacing(1),
  },
  title1: {
    display: "block",
    marginLeft: "10px",
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "52px",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderBottom: "1px solid #DCDCDC",
    background: theme.palette.background.table,
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "52px",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    background: theme.palette.background.tableHeader,
  },
  styledDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  container: {
    display: "flex",
  },
  content: {
    height: "Calc(100vh - 117px)",
    // padding: theme.spacing(1),
    background: theme.palette.background.common,
  },
  paper: {
    height: "100%",
    padding: theme.spacing(2),
    overflowY: "scroll",
    background: theme.palette.background.table,
  },
  microfrontcontent: {
    height: "Calc(100vh - 52px)",
    background: theme.palette.background.common,
  },
  wrapperdiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "16px",
    borderTop: " 1px solid #e0e0e0",
  },
  Table: {
    background: theme.palette.background.table,
    borderColor: theme.palette.border.textField,
    border: "1px solid",
  },
  TableHeader: {
    background: theme.palette.background.tableHeader,
  },
  TableBody: {
    background: theme.palette.background.table,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80%",
    // bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    background: theme.palette.background.table,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  style1: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    // bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    background: theme.palette.background.table,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  errorBoxStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    // bgcolor: "background.paper",
    borderRadius: 1,
    textAlign: "center",
    boxShadow: 24,
    p: 4,
    border: "none",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.text.primary,
    // color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.white,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const Home = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const dialogContext = React.useContext(DialogContext);
  const projectinfo = React.useContext(ProjectInfoContext);
  const [open, setOpen] = React.useState(false);
  const [isloading, setisloading] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [queue, setQueue] = React.useState([]);
  const [erropen, setError] = React.useState({
    open: false,
    errorMessage: "",
  });
  const alertMessage = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const [isdisable, SetDiasbled] = React.useState(false);
  const isMicroFrontEnd = sessionStorage.getItem("isMicroFrontEnd");
  React.useEffect(() => {
    // console.log(projectinfo?.call);
    if (projectinfo?.call) {
      getCreatedQueue();
    }

    // eslint-disable-next-line
  }, [projectinfo?.call]);

  const [cloneModel, setCloneModel] = React.useState({
    open: false,
    errorMessage: "",
  });

  const cloneModelopen = () => {
    setCloneModel({ ...cloneModel, open: true });
  };

  const cloneModelClose = () => {
    setCloneModel({ ...cloneModel, open: false });
    setClone({
      newname: "",
      cloningData: [],
    });
  };

  const [queueClone, setClone] = React.useState({
    newname: "",
    cloningData: [],
  });

  const onCloneClicked = () => {
    let isclonequeueClone =
      queueClone?.newname.trim().length === 0 ? false : true;
    let iscloningDataClone =
      queueClone?.cloningData?.length === 0 ? false : true;
    if (isclonequeueClone && iscloningDataClone) {
      heyCloneThisQueue(
        queueClone.newname,
        queueClone.cloningData,
        refreshQueue
      );
    } else if (!iscloningDataClone) {
      alertMessage.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "No Clone data selected",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    } else {
      alertMessage.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Clone Name can't be Empty",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  const refreshQueue = () => {
    cloneModelClose();
    getCreatedQueue();
    alertMessage?.setSnack({
      ...alertMessage,
      open: true,
      severity: AlertProps.severity.success,
      msg: "Queue Cloned Successfully",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
    });
  };

  const onChangeState = (key, value) => {
    setClone({ ...queueClone, [key]: value });
  };

  const getCreatedQueue = async () => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queue",
        ...(sessionStorage.getItem("tenantid")
          ? {
              filter: `queue.tenantid=='${sessionStorage.getItem(
                "tenantid"
              )}' && queue.activestatus==true`,
            }
          : { filter: `queue.activestatus==true` }),
        sort: "queue.queuename ASC",
        return_fields: "queue",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      setisloading(true);
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Fetching Queue Data",
      });
      await axios(config).then((res) => {
        // console.log(res);
        // let data = res.data.result;
        // console.log(res);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
        });
        if (res.data.result) {
          setQueue(res.data.result);
          sessionStorage.setItem("queueList", JSON.stringify(res.data.result));
          setisloading(false);
        } else {
          /*  alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: `${res.data.error}`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          }); */
          setQueue([]);
          SetDiasbled(true);
          setError({ ...erropen, open: true, errorMessage: res.data.error });
          setisloading(false);
        }
        // if(data)
      });
    } catch (error) {
      console.log(error);
    }
  };
  const onAddNewClicked = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let metadata_id = urlParams?.get("metadata_id");
    let token = urlParams?.get("token");
    history.push({
      pathname: Routes.createQueue,
      search: `metadata_id=${metadata_id}&token=${token}`,
    });
  };

  const onSettingClick = (data) => {
    // history.push(`${Routes.Config}${id}`);
    let encode = window.btoa(data?.queueid);
    let urlParams = new URLSearchParams(window.location.search);
    let metadata_id = urlParams?.get("metadata_id");
    let token = urlParams?.get("token");
    history.push({
      pathname: Routes.Config + encode,
      state: {
        data,
      },
      search: `metadata_id=${metadata_id}&token=${token}`,
    });
  };

  const onDeleteClicked = (_key, uniqueid) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: "Are you sure you want to delete this?",
      body: "",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => onConfrimDelete(_key, uniqueid),
    });
  };
  const onConfrimDelete = (_key, uniqueid) => {
    softdelete(_key);
    dialogContext.setDialog({
      ...dialogContext,
      open: false,
    });
    const rep = getRepoid(uniqueid);
    rep.then(function (result) {
      //  console.log(result.rep_id);
      if (result?.length > 0) {
        softDeleteRepo(result[0]._key);
      }
    });
  };
  const softdelete = async (_key) => {
    try {
      let params = {
        db_name: `${projectinfo?.project?.dbname}`,
        entity: "queue",
        filter: `queue._key=='${_key}'`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_SOFTDELETE}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        getCreatedQueue();
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Queue Deleted Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        // console.log(res);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e, _key) => {
    statusSwitch(e.target.checked, _key);
    // console.log(e.target.checked, _key)
  };

  const statusSwitch = async (status, _key) => {
    try {
      let params = [
        {
          db_name: `${projectinfo?.project?.dbname}`,
          entity: "queue",
          filter: {
            _key: `${_key}`,
          },
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            queuestatus: status,
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      setisloading(true);
      await axios(config).then((res) => {
        if (res.data.Code === 201) {
          getCreatedQueue();
          setisloading(false);
          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Queue Status Updated Successfully!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }

        // console.log(res);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.root}>
      {/* <Box className={classes.Header2}>
        <Typography className={classes.title} variant="body1" noWrap>
          {`${localStorage.getItem("clientname")}`}
        </Typography>
        <Typography className={classes.title} variant="body1" noWrap>
          {`${localStorage.getItem("projectName")} Queues`}
        </Typography>
      </Box> */}
      {/* <Divider /> */}
      <Box className={classes.Header}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {JSON.parse(isMicroFrontEnd) ? (
            <Typography sx={{ ml: "3px" }} variant="body1" noWrap>
              {"T&D's Workflow"}
            </Typography>
          ) : (
            <>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <PersonOutlineOutlinedIcon style={{ fontSize: "1.3em" }} />
                <Typography sx={{ ml: "3px" }} variant="body1" noWrap>
                  {`${projectinfo?.project?.clientname ?? ""}`}
                </Typography>
              </Box>

              <Divider
                orientation="vertical"
                sx={{ height: "20px", margin: "0 8px", borderColor: "#000" }}
              />
              <Box style={{ display: "flex", alignItems: "center" }}>
                <ProjectName />
                {/* <SourceOutlinedIcon style={{ fontSize: "1.3em" }} /> */}
                <Typography sx={{ ml: "4px" }} variant="body1" noWrap>
                  {`${projectinfo?.project?.projectname ?? ""}`}
                </Typography>
              </Box>
              <Divider
                orientation="vertical"
                sx={{ height: "20px", margin: "0 8px", borderColor: "#000" }}
              />
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Databaseicon />
                <Typography sx={{ ml: "4px" }} variant="body1" noWrap>
                  {`${projectinfo?.project?.dbname ?? ""}`}
                </Typography>
              </Box>
            </>
          )}
        </Box>

        <div className={classes.container}>
          <Button
            id={"Message Configuration"}
            onClick={() => cloneModelopen()}
            variant="outlined"
            color="primary"
            disabled={queue?.length !== 0 ? false : true}
            startIcon={<CloneIcon />}
            // style={{ background: "#ECF0F7" }}
          >
            {"Clone Queue"}
          </Button>
          <Button
            id={"Message Configuration"}
            onClick={handleOpen}
            variant="outlined"
            color="primary"
            disabled={isdisable}
            startIcon={<SettingsOutlinedIcon />}
            style={{ margin: "0 16px" }}
          >
            {"Message Configuration"}
          </Button>
          <Button
            id={"Add Button"}
            variant="contained"
            // color="secondary"
            disabled={isdisable}
            className={classes.Button}
            // style={{ marginLeft: "16px" }}
            onClick={() => onAddNewClicked()}
          >
            {"+Add New"}
          </Button>
        </div>
      </Box>
      <div
        className={
          JSON.parse(isMicroFrontEnd)
            ? classes.microfrontcontent
            : classes.content
        }
      >
        <Paper style={{ height: "100%" }}>
          <div className={classes.paper}>
            {queue?.length !== 0 ? (
              <TableContainer component={Paper} className={classes.Table}>
                <Table size="small" aria-label="customized table">
                  <TableHead className={classes.TableHeader}>
                    <TableRow>
                      <StyledTableCell align="left">Queues</StyledTableCell>
                      <StyledTableCell width={100} align="center">
                        Status
                      </StyledTableCell>
                      <StyledTableCell width={130} align="center">
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className={classes.TableBody}>
                    {queue?.map((item, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">
                          {item?.queuename}
                        </StyledTableCell>
                        <StyledTableCell width={100} align="center">
                          <Switch
                            checked={item?.queuestatus}
                            onChange={(e) => handleChange(e, item?._key)}
                          />
                        </StyledTableCell>
                        <StyledTableCell width={130} align="center">
                          <IconButton
                            id={"Edit Icon"}
                            disableRipple={true}
                            onClick={() => onSettingClick(item)}
                          >
                            <EditOutlinedIcon color="primary" />
                          </IconButton>
                          <IconButton
                            id={"Delete Icon"}
                            disableRipple={true}
                            onClick={() =>
                              onDeleteClicked(item?._key, item?._id)
                            }
                          >
                            <DeleteOutlineOutlinedIcon color="error" />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : isloading ? (
              <LinearProgress />
            ) : (
              <Typography align="center">{`No Queue Add still yet on ${projectinfo?.project?.projectname}!`}</Typography>
            )}
          </div>
        </Paper>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.style}>
          <MessageConfiguration
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <Modal
        open={erropen.open}
        // onClose={errhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.errorBoxStyle}>
          <ErrorOutlineIcon color="error" style={{ fontSize: "5rem" }} />
          <Typography
            id="modal-modal-title"
            color="error"
            variant="h6"
            component="h2"
            textAlign="center"
          >
            SORRY!
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="body1"
            component="h2"
            textAlign="center"
            sx={{ mt: 2, textTransform: "capitalize" }}
          >
            {`${erropen.errorMessage}. So We Cannot Create Queue Against This Project`}
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={cloneModel.open}
        onClose={cloneModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.style1}>
          <Box className={classes.Header}>
            <Typography variant="body1">{"Clone Queue"}</Typography>
          </Box>
          <Divider />
          <div style={{ padding: "24px 16px" }}>
            <Grid container>
              <Grid item xs={12} lg={12} sx={{ py: 1 }}>
                <Typography variant="body2" gutterBottom>
                  {"New Queue Name"}
                  <IsRequired />
                </Typography>
                <TextField
                  onChange={(e) => onChangeState("newname", e.target.value)}
                  placeholder="Enter New Name"
                  fullWidth
                  autoComplete="off"
                  size="small"
                  name="states"
                  // error={err}
                  // helperText={err === true ? Message.requiredMessage : ""}
                  value={queueClone?.newname}
                />
              </Grid>
              <Grid item xs={12} sx={{ py: 1 }}>
                <Typography variant="body2" gutterBottom>
                  {"Select a Queue to Clone"}
                </Typography>
                <Autocomplete
                  id="choosetype"
                  options={queue}
                  getOptionLabel={(option) => option.queuename || ""}
                  value={queueClone?.cloningData}
                  fullWidth
                  name="choosetype"
                  // filterSelectedOptions
                  size="small"
                  onChange={(e, value) => onChangeState("cloningData", value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      size="small"
                      name="Queue Clone"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
          <Divider />

          <div className={classes.wrapperdiv}>
            <Button
              id={"Cancel Button"}
              onClick={() => cloneModelClose()}
              variant="outlined"
            >
              {"Cancel"}
            </Button>

            <Button
              id={"Update Button"}
              onClick={() => onCloneClicked()}
              variant="contained"
              sx={{ ml: "16px" }}
              className={classes.Button}
            >
              {"Clone"}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const heyCloneThisQueue = async (newname, cloningData, refreshQueue) => {
  // let readRule = await getRuleData(cloningData?.entrycriteria[0]);
  // let existRuleData = readRule.result[0];
  // let upsertCloneRule = await upsertRule(existRuleData);
  debugger;
  let upsertCloneRule = await heyCloneThisRule(
    cloningData?.entrycriteria[0] ?? ""
  );

  /* Inserting a copy of that existRuleData */

  let queueDoc = {
    queueid: "",
    queuename: newname,
    msgqueuegroup: cloningData?.msgqueuegroup,
    queuedescription: cloningData?.queuedescription,
    entrycriteria: upsertCloneRule,
    inputschema: cloningData?.inputschema,
    queuestatus: true,
    version: "",
    active: true,
    projectid: `${localStorage.getItem("projectId")}`,
    clientid: `${localStorage.getItem("clientId")}`,
    metedataid: `${localStorage.getItem("metaDataId")}`,
    tenantid: `${sessionStorage.getItem("tenantid")}`,
    idmid: cloningData?.idmid,
    // idmrole: cloningData?.idmrole,
  };

  let clonedQueue = await upsertQueue(queueDoc);

  let SurveilanceClientId = sessionStorage.getItem("SurveilanceClientId");
  let clientRepId = await getRepoid(SurveilanceClientId);

  let parentUniqueId = process.env.REACT_APP_IDM_WORKFLOW;

  let Repositry = await getRepoIdStateMapping(
    clientRepId[0]?.rep_id,
    parentUniqueId
  );

  let parenRepid = Repositry[0]?.rep_id;
  let upsertRepoCloneQueue = await upsertRepoClone(
    "Task and Document",
    clonedQueue.queuename,
    parenRepid,
    clonedQueue._id
  );

  let QueueStates = await getQueueStates(cloningData?._id);

  let stateList = QueueStates?.result ?? [];

  let QueueStateClone = await stateList?.map(async (l) => {
    let doc = {
      queueid: clonedQueue?._id,
      statesid: "",
      states: l?.states,
      version: "1",
      active: true,
    };
    HeyCloneThisState(doc, l._id, cloningData?._id);
  });

  Promise.all(QueueStateClone).then(async function (results) {
    console.log("Cloned State Successfully");
  });

  /* Updating Scenario State Mapping State_id */

  let QueueScenarios = await getScenariosData(cloningData?._id);

  let scenarios = QueueScenarios?.result ?? [];

  let ScenarioClone = scenarios?.map(async (scenario) => {
    HeyCloneThisScenario(
      scenario.scenarioname,
      scenario,
      clonedQueue?._id,
      refreshQueue,
      true,
      upsertRepoCloneQueue?.rep_id /*for  parent rep id*/
      // refreshQueue,
    );
  });

  Promise.all(ScenarioClone).then(async function (results) {
    console.log("Cloned Scenario Successfully");
  });
};

export const HeyCloneThisState = async (doc, State_id, existingQueueID) => {
  let upsertState = await upsertQueueState(doc);
  let QueueAction = await getQueueAction(existingQueueID, State_id);
  let stateAction = QueueAction?.result ?? [];
  let CloneQueueStateSction = await stateAction?.map(async (l) => {
    return await upsertAction(l, upsertState?._id, upsertState?.queueid);
  });
  Promise.all(CloneQueueStateSction).then(async function (results) {
    console.log("Cloned action Successfully");
  });
};

export const upsertQueue = async (doc) => {
  try {
    let params = [
      {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queue",
        is_metadata: true,
        metadataId: `${localStorage.getItem("metaId")}`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
        doc: doc,
      },
    ];

    let _ = await AxiosCall(
      process.env.REACT_APP_NODERED_BASE_URL_UPSERT,
      params
    );

    let res = _.data;
    if (res.Code === 201) {
      // refresh();
      return res.Result[0]?.properties?.doc;
    }
  } catch (error) {
    console.log(error);
  }
};

const upsertQueueState = async (doc) => {
  try {
    let params = [
      {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuestates",
        is_metadata: true,
        metadataId: `${localStorage.getItem("metaId")}`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
        doc: doc,
      },
    ];
    let _ = await AxiosCall(
      process.env.REACT_APP_NODERED_BASE_URL_UPSERT,
      params
    );

    let res = _.data;
    if (res.Code === 201) {
      return res.Result[0]?.properties?.doc;
    }
  } catch (error) {
    console.log(error);
  }
};

const upsertAction = async (l, states_id, queueid) => {
  let datas = {
    queueid: queueid,
    statesid: states_id,
    actionid: "",
    action: l?.action,
    apiendpoint: l?.apiendpoint,
    chooseform: l?.chooseform,
    choosetype: l?.choosetype,
    // isEdit: false,
    version: "",
    active: true,
  };
  try {
    let params = [
      {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuestatesaction",
        is_metadata: true,
        metadataId: `${localStorage.getItem("metaId")}`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
        doc: datas,
      },
    ];
    let _ = await AxiosCall(
      process.env.REACT_APP_NODERED_BASE_URL_UPSERT,
      params
    );

    let res = _.data;
    if (res.Code === 201) {
      return res.Result[0]?.properties?.doc;
    }
  } catch (error) {
    console.log(error);
  }
};

const getScenariosData = async (queueid) => {
  try {
    let params = {
      db_name: `${localStorage.getItem("ProjectDbname")}`,
      entity: "queuescenarios",
      filter: `queuescenarios.queueid=='${queueid}'&&queuescenarios.active==true && queuescenarios.activestatus==true`,
      sort: "queuescenarios.scenarioname ASC",
      return_fields: "queuescenarios",
    };
    let res = await AxiosCall(
      process.env.REACT_APP_NODERED_BASE_URL_READ,
      params
    );
    return res?.data;
  } catch (error) {}
};

export const getQueueStates = async (queueid) => {
  try {
    let params = {
      db_name: `${localStorage.getItem("ProjectDbname")}`,
      entity: "queuestates",
      filter: `queuestates.queueid=='${queueid}' &&queuestates.active==true && queuestates.activestatus==true`,
      return_fields: `merge(unset(queuestates,'createdby','createddate','updatedby','updatedate'),{queuestatesaction:(for queuestatesaction in queuestatesaction filter queuestatesaction.statesid==queuestates._id && queuestatesaction.activestatus==true return unset(queuestatesaction,'createdby','createddate','updatedby','updatedate'))})`,
    };
    let res = await AxiosCall(
      process.env.REACT_APP_NODERED_BASE_URL_READ,
      params
    );
    let _ = await res?.data;
    return _;
  } catch (error) {
    console.log(error);
  }
};

const getQueueAction = async (queueid, state_id) => {
  try {
    let params = {
      db_name: `${localStorage.getItem("ProjectDbname")}`,
      entity: "queuestatesaction",
      filter: `queuestatesaction.queueid=='${queueid}'&& queuestatesaction.statesid=='${state_id}'  && queuestatesaction.activestatus==true`,
      return_fields: "queuestatesaction",
    };
    let res = await AxiosCall(
      process.env.REACT_APP_NODERED_BASE_URL_READ,
      params
    );
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const upsertRepoClone = async (reptype, repname, parent_rep, _id) => {
  try {
    // debugger;
    let params = [
      {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "IDM_Repository",
        is_metadata: true,
        metadataId: `${localStorage.getItem("metaId")}`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
        doc: {
          rep_id: uuidv4(),
          rep_type: reptype,
          rep_name: repname,
          parent_rep: parent_rep ?? "",
          route_url: "",
          is_active: true,
          unique_id: _id,
          projectid: `${localStorage.getItem("projectId")}`,
          clientid: `${localStorage.getItem("clientId")}`,
          metadataid: `${localStorage.getItem("IDMmetaId")}`,
        },
      },
    ];
    let _ = await AxiosCall(
      process.env.REACT_APP_NODERED_BASE_URL_UPSERT,
      params
    );

    let res = _.data;

    return res.Result[0]?.properties?.doc;
  } catch (error) {
    console.log(error);
  }
};
